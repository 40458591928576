<template>
  <div>
    <NavBar/>      <!--  导航栏  -->
    <router-view :dataObj="data" :key="key"/>
    <Footer/>

    <div class="suspension">
      <div class="p1">
        <span class="icon icondianhua01 icona"></span>
        <span class="t">咨询<br>热线</span>
        <div class="left">
          <span class="icon icondianhua01"></span>
          <div class="s">
<!--            <div class="phone">13666168545</div>-->
            <div class="phone">薛先生 18402841794（微信同）
<!--              18980418191 李先生（微信同）-->
            </div>
            <div class="txt">请拨打咨询热线</div>
          </div>
        </div>
      </div>
      <div class="p2">
        <span class="icon iconfangweima1"></span>
        <span class="t">微信 <br>客服</span>
        <div class="left">
          <img src="../assets/qrcode.png" alt="">
          <div>跟我聊聊吧</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import { home } from "../api/home";

export default {
  name: "mainBody",
  components: {
    NavBar,
    Footer
  },
  data() {
    return {
      data: {}
    }
  },
  async beforeCreate() {

    const { data, code, message } = await home();

    if ( code !== 200 ) return this.$message.error( message );

    this.data = data;
    console.log("主页加载前")
    localStorage.setItem( 'data', JSON.stringify( data ) );
  },
  computed: {
    key() {
      return this.$route.path + Math.random()
    }
  }
}
</script>

<style lang="less" scoped>

.suspension {
  position: fixed;
  right: 15px;
  bottom: 120px;
  z-index: 9999;

  > div {
    position: relative;
    width: 75px;
    height: 75px;
    background: #FFFFFF;
    border: 2px solid #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .icon {
      font-size: 45px;
    }

    .t {
      font-size: 20px;
      font-family: Source Han Sans CN, sans-serif;
      font-weight: 500;
      color: #248FFF;
      display: none;
    }

  }

  div:first-child {
    margin-bottom: 40px;
  }

  .p1 {
    position: relative;

    .left {
      display:none;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      left: -305px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      box-shadow: 0 3px 8px 0 rgba(161, 169, 182, 0.17);
      border-top: rgba(36, 143, 255, 1) 8px solid;
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      color: #248FFF;
      padding: 15px;
      .icon {
        font-size: 35px;
      }

      .s {
        margin-left: 30px;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        .phone {
          font-size: 25px
        }

        .txt {
          font-size: 17px;
          font-weight: 500;
          color: #A69C9C;
        }

        > div {
          margin: 0;
        }
      }

    }
  }

  .p2 {
    position: relative;

    .left {
      position: absolute;
      left: -170px;
      width: 130px;
      height: 160px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      box-shadow: 0 3px 8px 0 rgba(161, 169, 182, 0.17);
      border-top: rgba(36, 143, 255, 1) 8px solid;
      display: none;
      text-align: center;
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #248FFF;
      padding: 12px 10px;
    }
  }


  .p1:hover {
    .icona {
      display: none;
    }

    .t {
      display: block;
    }

    .left {
      display: flex;
    }

  }

  .p2:hover {
    .icon {
      display: none;
    }

    .t {
      display: block;
    }

    .left {
      display: block;
    }

  }

}


</style>
