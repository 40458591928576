<template>
  <div>
    <nav>
      <div class="logTxt">
        <img src="../assets/logo/2024Logo.png" alt="">
        <div></div>
        <!--        四川省虎虾网络科技有限公司-->
      </div>
      <div class="nBar">

        <el-menu
            :default-active="$route.path"
            :default-openeds="[$route.path]"
            class="el-menu"
            mode="horizontal"
            @select="handleSelect"
            text-color="#fff"
            active-text-color="#5581fd"
            show-timeout="1000"
            hide-timeout="1000"

            router
        >

          <template v-for="(v,i) in navList">
            <el-menu-item :index="v.url" v-if="!v.children">{{ v.title }}</el-menu-item>

            <el-submenu v-else :index="v.title">
              <template slot="title">{{
                  v.title
                }}
              </template>
              <el-menu-item v-for="(vv,vi) in v.children" :key="vi"
                            :index="v.url == '/news' ? `${v.url +  vv.id}`  : `${v.url}?id=${vv.id}`">{{
                  vv.title
                }}
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </div>

<!--      <div class="contact">13666168545 18980418191</div>-->
      <div class="contact"> 薛先生 18402841794（微信同）
<!--        18980418191 李先生（微信同）-->
      </div>


    </nav>
    <div class="nn"></div>
  </div>
</template>

<script>
import { home } from '@/api/home';

export default {
  name: "NavBar",
  data() {
    return {
      navList: [
        {
          title: '首页',
          url: '/home',
        },
        {
          title: '产品',
          url: '/product',
          children: [],
        },
        {
          title: '解决方案',
          url: '/solution',
          children: [],
        },
        {
          title: '案例',
          url: '/case',
          children: [],
        },
        {
          title: '新闻资讯',
          url: '/news',
          children: [
            { title: '企业动态', id: '?type=1' },
            { title: '行业资讯', id: '?type=2' },
          ],
        },
        {
          title: '我们',
          url: '/we',
        },
      ]
    }
  },
  created() {
    this.home()
  },
  mounted() {
  },
  methods: {
    handleSelect( path ) {
      this.$router.push( { path } );
    },
    go( path ) {
      this.$router.push( { path } );
    },
    async home() {
      const { data, code, message } = await home();

      if ( code !== 200 ) return this.$message.error( message );

      localStorage.setItem( 'data', JSON.stringify( data ) );

      for ( let dataKey in data ) {
        if ( dataKey != 'news' && dataKey != 'banner' ) {
          const n = this.navList.find( f => f.url.indexOf( dataKey ) > -1 );
          n.children = data[dataKey];
        }

      }
    }
  }
}
</script>

<style lang="less" scoped>
@navHeight: 75px;
@navFont-size: 18px;
@navFont-family: Microsoft YaHei;
.nn {
  width: 100%;
  height: @navHeight;
}

nav {

  position: fixed;
  z-index: 99999;
  top: 0;
  width: 100%;
  height: @navHeight;
  background-color: #000000;
  //padding: 0 150px 0 100px;
  color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;


  .contact {
    font-size: 25px;
  }

  .logTxt {
    //position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 25px;
    font-family: HYRuiZhiW, serif;
    font-weight: 400;
    color: #FFFFFF;
    left: 80px;

    img {
      width: 180px;
    }

    > div {
      width: 30px;
    }
  }


  .nBar {
    //position: absolute;
    right: 80px;
    display: flex;

    .el-menu {
      background: none;
      border: none;

      .el-menu-item {
        width: 130px;
        margin: 0 10px;
        text-align: center;
        height: @navHeight;
        line-height: @navHeight;
        border: none;
        padding: 0;
        background: transparent;
        font-size: @navFont-size !important;
        font-family: @navFont-family;
        font-weight: 500;

      }

      .el-menu-item:hover {
        background-color: #9ac3df1c !important;
        color: #5581fd !important;

      }


      .el-submenu {
        /deep/ .el-submenu__title {
          width: 130px;
          margin: 0 10px;
          text-align: center;
          height: @navHeight;
          line-height: @navHeight;
          border: none;
          background: transparent !important;
          font-size: @navFont-size !important;
          font-family: @navFont-family;
          font-weight: 500;


        }

        /deep/ i {
          display: none;
        }

        /deep/ .el-submenu__title:hover {
          background-color: #9ac3df1c !important;
          color: #5581fd !important;

        }

      }


    }


    .item {
      width: 130px;
      margin: 0 10px;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      height: @navHeight;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .sonNav {
        position: absolute;
        width: 100%;
        background-color: #202020;
        height: 0;
        z-index: 0;
        top: 75px;
        text-align: center;
        overflow: hidden;

        a {
          display: block;
          width: 100%;
          height: 48px;
          background: #202020;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 48px;

        }
      }


    }

    .item:hover {
      color: #5581fd;
    }

    .item:hover .sonNav {
      transition: .5s;
      z-index: 10;
      height: 200px;

      a:hover {
        background-color: #FF5B5B;
      }
    }
  }
}

</style>

<style>


.el-menu--horizontal .el-menu--popup {
  padding: 0 !important;
  background: #000;

}

.el-menu--horizontal .el-menu--popup li {
  text-align: center;
  height: 45px !important;
  line-height: 45px !important;
  background: #202020;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  margin: auto;
  background: transparent !important;

}

.el-menu--horizontal .el-menu--popup li:hover {
  background: rgb(201 79 79) !important;
  color: #FFFFFF !important;

}


</style>
